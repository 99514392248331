import { z } from 'zod'
import { bzOptional } from '..'
import { guidSchema } from '../../contracts'

export const JOB_LEAD_STATUSES = ['open', 'converted', 'archived'] as const

export type JobLeadStatus = (typeof JOB_LEAD_STATUSES)[number]

export const JobLeadAvailabilitySchema = z.object({
  times: z.array(
    z.object({
      type: z.string(),
      value: z.string(),
    }),
  ),
})

export type JobLeadAvailability = z.infer<typeof JobLeadAvailabilitySchema>

const PhoneCallMetadata = z.object({
  type: z.literal('phone-call'),
  data: z.object({
    externalCallId: bzOptional(z.string()),
    summary: bzOptional(z.string()),
    transcript: bzOptional(z.string()),
    recordingUrl: bzOptional(z.string()),
  }),
})

export const JobLeadAdditionalMetadataSchema = z.discriminatedUnion('type', [PhoneCallMetadata])

export type JobLeadAdditionalMetadata = z.infer<typeof JobLeadAdditionalMetadataSchema>

export const CreateJobLeadRequestSchema = z.object({
  jobLeadGuid: bzOptional(guidSchema),
  contactFirstName: bzOptional(z.string().describe('First Name')),
  contactLastName: bzOptional(z.string().describe('Last Name')),
  contactPhoneNumber: bzOptional(z.string().describe('Phone Number')),
  contactEmailAddress: bzOptional(z.string().describe('Email Address')),
  serviceAddressLine1: bzOptional(z.string().describe('Service Location Line 1')),
  serviceAddressLine2: bzOptional(z.string().describe('Service Location Line 2')),
  serviceAddressCity: bzOptional(z.string().describe('Service Location City')),
  serviceAddressStateAbbreviation: bzOptional(z.string().describe('Service Location State')),
  serviceAddressZipCode: bzOptional(z.string().describe('Service Location Zip Code')),
  accountType: bzOptional(z.string().describe('Type of Customer')),
  jobType: bzOptional(z.string().describe('Type of Service')),
  appointmentAvailabilities: bzOptional(
    JobLeadAvailabilitySchema.describe('Appointment Availability').or(z.string().array()),
  ),
  additionalMetadata: bzOptional(JobLeadAdditionalMetadataSchema),
  jobSummary: bzOptional(z.string().describe('Additional Details')),
  leadSource: bzOptional(z.string().describe('Lead Source')),
})

export type CreateJobLeadRequest = z.infer<typeof CreateJobLeadRequestSchema>
