import { gql } from '../../generated'

export const FETCH_JOB_LEADS = gql(/* GraphQL */ `
  query FetchJobLeads(
    $companyGuid: uuid!
    $timeStart: timestamptz
    $timeEnd: timestamptz
  ) {
    jobLeads(
      where: {
        companyGuid: { _eq: $companyGuid }
        createdAt: { _gte: $timeStart, _lte: $timeEnd }
      }
      orderBy: { createdAt: DESC }
    ) {
      jobLeadGuid
      contactFirstName
      contactLastName
      contactPhoneNumber
      contactEmailAddress
      serviceAddressLine1
      serviceAddressLine2
      serviceAddressCity
      serviceAddressStateAbbreviation
      serviceAddressZipCode
      accountType
      jobType
      appointmentAvailabilities
      additionalMetadata
      createdAt
      jobSummary
      leadSource
      jobLeadStatus
      jobGuid
      job {
        displayId
        createdAt
        jobType {
          name
        }
      }
    }
  }
`)

export const FETCH_JOB_LEADS_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription FetchJobLeadsSubscription(
    $companyGuid: uuid!
    $timeStart: timestamptz
    $timeEnd: timestamptz
  ) {
    jobLeads(
      where: {
        companyGuid: { _eq: $companyGuid }
        createdAt: { _gte: $timeStart, _lte: $timeEnd }
      }
      orderBy: { createdAt: DESC }
    ) {
      jobLeadGuid
      contactFirstName
      contactLastName
      contactPhoneNumber
      contactEmailAddress
      serviceAddressLine1
      serviceAddressLine2
      serviceAddressCity
      serviceAddressStateAbbreviation
      serviceAddressZipCode
      accountType
      jobType
      appointmentAvailabilities
      additionalMetadata
      createdAt
      jobSummary
      leadSource
      jobLeadStatus
      jobGuid
      job {
        displayId
        createdAt
        jobType {
          name
        }
      }
    }
  }
`)

export const FETCH_JOB_LEAD_BY_JOB_LEAD_GUID = gql(/* GraphQL */ `
  query FetchJobLeadByJobLeadGuid($jobLeadGuid: uuid!) {
    jobLeads(where: { jobLeadGuid: { _eq: $jobLeadGuid } }, limit: 1) {
      jobLeadGuid
      contactFirstName
      contactLastName
      contactPhoneNumber
      contactEmailAddress
      serviceAddressLine1
      serviceAddressLine2
      serviceAddressCity
      serviceAddressStateAbbreviation
      serviceAddressZipCode
      accountType
      jobType
      appointmentAvailabilities
      additionalMetadata
      createdAt
      jobSummary
      leadSource
      jobLeadStatus
      jobGuid
      job {
        displayId
        createdAt
        jobType {
          name
        }
      }
    }
  }
`)

export const UPDATE_JOB_LEAD_STATUS = gql(/* GraphQL */ `
  mutation UpdateJobLeadStatus(
    $set: JobLeadsSetInput
    $companyGuid: uuid!
    $jobLeadGuid: uuid!
  ) {
    updateJobLeads(
      _set: $set
      where: {
        companyGuid: { _eq: $companyGuid }
        jobLeadGuid: { _eq: $jobLeadGuid }
      }
    ) {
      affectedRows
    }
  }
`)

export const CONVERT_JOB_LEAD = gql(/* GraphQL */ `
  mutation ConvertJobLead($jobLeadGuid: uuid!, $jobGuid: uuid!) {
    updateJobLeadsByPk(
      _set: { jobGuid: $jobGuid, jobLeadStatus: "converted" }
      pkColumns: { jobLeadGuid: $jobLeadGuid }
    ) {
      __typename
    }
  }
`)

export const FETCH_COUNT_OF_OPEN_JOB_LEADS = gql(/* GraphQL */ `
  subscription FetchCountOfOpenJobLeadsByCompany($companyGuid: uuid!) {
    jobLeadsAggregate(
      where: {
        companyGuid: { _eq: $companyGuid }
        jobLeadStatus: { _eq: "open" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`)
